import Vue from "vue";

import {
	Button,
	Col,
	Row,
	Cell,
	CellGroup,
	Icon,
	Field,
	Form,
	Toast,
	Search,
	List,
	Checkbox,
	Picker,
	Popup,
	DatetimePicker,
	Image,
	CountDown,
	Loading,
	Overlay,
	Radio,
	Skeleton,
	RadioGroup,
	// Area,
	Tab,
	Tabs,
	Dialog,
	Cascader,
	NavBar,
	Collapse,
	CollapseItem,
	Switch,
} from "vant";

Vue.use(Button);



Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Icon);
Vue.use(Field);
Vue.use(Form);
Vue.use(Toast);
Vue.use(Search);
Vue.use(List);
Vue.use(Skeleton);
Vue.use(Checkbox);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Image);
Vue.use(CountDown);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Radio);
Vue.use(RadioGroup);
// Vue.use(Area);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);
Vue.use(Cascader);
Vue.use(NavBar);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Switch);
